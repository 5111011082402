// Code generated by `rails fontawesome:update`. DO NOT EDIT.
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

// Add all free regular icons for customization of external pages
import { far as freeRegularIcons } from '@fortawesome/free-regular-svg-icons'

import {
  faAddressCard as farAddressCard,
  faArrowDown as farArrowDown,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowRightToBracket as farArrowRightToBracket,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faBarcodeRead as farBarcodeRead,
  faBars as farBars,
  faCalendar as farCalendar,
  faCalendarDay as farCalendarDay,
  faCheck as farCheck,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faCircle as farCircle,
  faCircleCheck as farCircleCheck,
  faCircleDot as farCircleDot,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleUser as farCircleUser,
  faCircleXmark as farCircleXmark,
  faClipboardList as farClipboardList,
  faClose as farClose,
  faCog as farCog,
  faComments as farComments,
  faDesktop as farDesktop,
  faEllipsis as farEllipsis,
  faEnvelope as farEnvelope,
  faExclamation as farExclamation,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFile as farFile,
  faFileImport as farFileImport,
  faFolder as farFolder,
  faFolderMedical as farFolderMedical,
  faGlobe as farGlobe,
  faHeart as farHeart,
  faHeartCrack as farHeartCrack,
  faHome as farHome,
  faHospital as farHospital,
  faKeySkeleton as farKeySkeleton,
  faListCheck as farListCheck,
  faLocationDot as farLocationDot,
  faLock as farLock,
  faMagnifyingGlass as farMagnifyingGlass,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faMobile as farMobile,
  faNotebook as farNotebook,
  faPalette as farPalette,
  faPaperPlane as farPaperPlane,
  faPaperclip as farPaperclip,
  faPhone as farPhone,
  faPlus as farPlus,
  faShare as farShare,
  faShareNodes as farShareNodes,
  faSignsPost as farSignsPost,
  faTablet as farTablet,
  faTrashCan as farTrashCan,
  faUnlock as farUnlock,
  faUpload as farUpload,
  faUser as farUser,
  faUserDoctor as farUserDoctor,
  faUsers as farUsers,
  faVideo as farVideo
} from '@fortawesome/pro-regular-svg-icons'

import {
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircleCheck as fasCircleCheck,
  faCircleXmark as fasCircleXmark,
  faCog as fasCog,
  faCopy as fasCopy,
  faExclamation as fasExclamation,
  faHeart as fasHeart,
  faTrashCan as fasTrashCan
} from '@fortawesome/pro-solid-svg-icons'


config.mutateApproach = 'sync'

// Add all used icons in here!
library.add(
  freeRegularIcons,
  farAddressCard,
  farArrowDown,
  farArrowRightFromBracket,
  farArrowRightToBracket,
  farArrowUpRightFromSquare,
  farBarcodeRead,
  farBars,
  farCalendar,
  farCalendarDay,
  farCheck,
  farCheckCircle,
  farChevronDown,
  farChevronRight,
  farChevronUp,
  farCircle,
  farCircleCheck,
  farCircleDot,
  farCircleExclamation,
  farCircleInfo,
  farCircleUser,
  farCircleXmark,
  farClipboardList,
  farClose,
  farCog,
  farComments,
  farDesktop,
  farEllipsis,
  farEnvelope,
  farExclamation,
  farEye,
  farEyeSlash,
  farFile,
  farFileImport,
  farFolder,
  farFolderMedical,
  farGlobe,
  farHeart,
  farHeartCrack,
  farHome,
  farHospital,
  farKeySkeleton,
  farListCheck,
  farLocationDot,
  farLock,
  farMagnifyingGlass,
  farMagnifyingGlassMinus,
  farMagnifyingGlassPlus,
  farMobile,
  farNotebook,
  farPalette,
  farPaperPlane,
  farPaperclip,
  farPhone,
  farPlus,
  farShare,
  farShareNodes,
  farSignsPost,
  farTablet,
  farTrashCan,
  farUnlock,
  farUpload,
  farUser,
  farUserDoctor,
  farUsers,
  farVideo,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCircleCheck,
  fasCircleXmark,
  fasCog,
  fasCopy,
  fasExclamation,
  fasHeart,
  fasTrashCan
)

dom.watch()
