import { Controller } from '@hotwired/stimulus'

import {
  loadDeviceKeyPairForPassword,
  generateAndUploadDeviceKeyPairForPassword,
} from '../encryption/encryption'

/**
 * LoginController
 */
export default class LoginController extends Controller<HTMLFormElement> {
  static targets = ['password']

  async onSubmitEnd(event: CustomEvent) {
    if (event.detail.fetchResponse.response.url.includes('/encryption/unlocking')) {
      event.preventDefault()
      const password = this.passwordTarget.value
      try {
        const deviceKey = await loadDeviceKeyPairForPassword(password)
        if (!deviceKey) {
          await generateAndUploadDeviceKeyPairForPassword(password)
        }
      } catch (e) {
        window.location.assign('/encryption/unlocking_error')
      }
    }
  }

  connect() {
    window.sessionStorage.removeItem('deviceKey')
    window.sessionStorage.removeItem('reset_password_token')
  }

  declare passwordTarget: HTMLInputElement
}
